import React from "react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

export default function Tyota () { return (
    <div>
     <h1 className="ekaotsikko_sivu" >Työtä tarjolla/We're hiring</h1>
   
    <Container>
<Row className="float-right">
  <Col>
    <Image className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/tyota_metsapalvelut_ilomantsi_joensuu_kitee_tohmaj%C3%A4rvi.jpg" rounded fluid/>
  </Col>
 </Row>
</Container>
      
<p></p>

<p className="esittely_sivu">Rekrytoimme metsä-ammatilaisia kausityöntekijöiksi touko-syyskuun ajaksi metsänhoidon ja metsuritöiden tehtäviin. Lisäksi konepalvelumme työllistää hakkuukoneen kuljettajia ympäri vuoden.  Mikäli olet fyysisesti hyvässä kunnossa, ja olet ahkera sekä haluat kehittyä metsänhoidon ammattilaiseksi niin lähetä yhteystietosi haastattelua varten. Työskentely paikkoina ovat Ilomantsin, Kiteen, Tohmäjärven ja Itäisen Joensuun alueet.</p>

<p className="esittely_sivu">We are looking for seasonal lumberjacks and forestry workers to works for us between May and September. The work area is in eastern part of Finland. Accommodation will be arranged.  If you have good physical condition and you are hardworking plus want to develop your forestry competencies please contact us.</p>


</div>
);

}