import React from "react";
import Karuselli from "./carousel";
import Kokemus, { Esittely, Jatkuvakasvatus} from "./yhtio";
import Puukauppa, { Ilmanavohakkuuta, Metsuripalvelut } from "./palvelut";
import Vinkit from "./vinkit";
import Yhteystiedot from "./yhteystiedot";
import Lomake from "./lomake";
import Popup from "./popup";
import { Link } from "react-router-dom";

function Etusivu() {
  return (
    <div>
      <div>
        <Karuselli />
      </div>

      <div>
        <h1 className="ekaotsikko">
          Kaikki metsäpalvelut joustavasti ja kustannustehokkaasti
        </h1>
        <div className="imageholder">
          <div className="hoover center">
          <Link className="app" to="/puukauppa_joensuu_kitee_ilomantsi_tohmajarvi"><h3 >Puukauppa</h3>
            </Link>
            <Link className=" kuva" to="/puukauppa_joensuu_kitee_ilomantsi_tohmajarvi">
              {Puukauppa}
            </Link>
          </div>
          <div className="hoover center">
          <Link className="app" to="/harvennushakkuut_joensuu_ilomantsi_kitee_tohmajarvi"><h3>Hakkuupalvelut</h3>
            </Link>
            <Link className=" kuva" to="/harvennushakkuut_joensuu_ilomantsi_kitee_tohmajarvi">
              {Ilmanavohakkuuta}
            </Link>
          </div>
          <div className="hoover center">
            <Link className="app" to="/metsanhoitopalvelu_joensuu_kitee_ilomantsi_tohmajarvi"><h3>Metsänhoitopalvelut</h3>
            </Link>
            <Link className="kuva" to="/metsanhoitopalvelu_joensuu_kitee_ilomantsi_tohmajarvi">
              {Metsuripalvelut}
            </Link>
          </div>
          <section className="kappale">
            <h1 className="ekaotsikko ">
              Puukauppaa ja korjuuta ilman avohakkuita - jatkuva kasvatus
            </h1>
            <div className="esittely">{Jatkuvakasvatus}</div>
          </section>
          <div>
            <h2 className="ekaotsikko">
              Yleisimpiä metsänomistajien esittämiä kysymyksiä
            </h2>
          </div>
        </div>
        <Vinkit />
        <div className="kappale">
        <h1 className="ekaotsikko kappale">{Kokemus}</h1>
        <h2 className="esittely">{Esittely}</h2>
      </div>
      </div>
      <div>
        <h1 className="ekaotsikko">
          <i>Asiakastarina
          </i>
        </h1>
        <div className="keskita">
        <Link className="vari"  to="/jatkuva_kasvatus_joensuu_ilomantsi_kitee_tohmajarvi">
        <h2 className="ekaotsikko_sivu">Lue kuinka metsatalouden professori Timo Pukkalan metsässä toteutettiin hakkuu ja millainen oli työn lopputulos </h2>
          </Link>
                  </div>
        </div>
      <div className="kappale"  id="yhteystiedot">
        <h1 className="ekaotsikko">Yhteystiedot</h1>
        <Yhteystiedot />
      </div>
      <h2 className="ekaotsikko">
        Tarjouspyyntölomake – olemme valmiina palvelemaan
      </h2>
    
      <h2 className="lomake esittely">
        Voit jättää yhteydenottopyynnön oheisella lomakkeella. Jotta voimme
        käsitellä tarjouspyynnön tarvitsemme yhteystietosi sekä kuvauksen
        toivotusta palvelusta - kiitos.
      </h2>
      <div className="lomake esittely">
        <Lomake />
      </div>
      <div>
        <Popup />
      </div>
    </div>
  );
}

export default Etusivu;
