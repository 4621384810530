import React from "react";
import Slogan from "./otsikko";
import MyVerticallyCenteredModal from "./suostumus";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import  { HashLink as Link}  from 'react-router-hash-link';

export default function Navi() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
      <div>
        <div>
          <Link className="app" to="/etusivu">
            Metsä-Karelia Oy
          </Link>
          <h2 className="slogan mobiili">{Slogan}</h2>
        </div>

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link eventKey="8" as={Link} to="/puukauppa_joensuu_kitee_ilomantsi_tohmajarvi">
               
                PUUKAUPPA
              </Nav.Link>
              <Nav.Link eventKey="3" as={Link} to="/harvennushakkuut_joensuu_ilomantsi_kitee_tohmajarvi">
                HAKKUUPALVELUT
              </Nav.Link>
              <Nav.Link eventKey="4" as={Link} to="/metsanhoitopalvelu_joensuu_kitee_ilomantsi_tohmajarvi">
                METSÄNHOITOPALVELUT
              </Nav.Link>
              <Nav.Link eventKey="5" as={Link} to="/tyotatarjolla">
                TYÖTÄ TARJOLLA
              </Nav.Link>
              <Nav.Link eventKey="6" as={Link} smooth to="/etusivu#yhteystiedot">
                YHTEYSTIEDOT
              </Nav.Link>
              <Nav.Link eventKey={7} onClick={() => setModalShow(true)}>
                METSÄÄN.FI SUOSTUMUS
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
}
