import React from "react";
import Carousel from "react-bootstrap/Carousel";
export default function Karuselli() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/slider/uusi_kone_metsakarelia_puukauppa-min.jpg"
          alt="Metsäpalvelu"
        />
        <Carousel.Caption>
          <h5>Metsänomistajan asialla Joensuun, Ilomantsin, Kiteen ja Tohmajärven alueilla</h5>
          <p>Yli 40 vuoden kokemus metsänhoidosta ja metsäpalveluista</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/slider/koivu_mets%C3%A4karelia_puukauppa_joensuu-min.jpg"
          alt="Puukauppa"
        />

        <Carousel.Caption>
          <h5>Olemme paikallinen, luotettava ja kilpailukykyinen</h5>
          <p>Teemme puukauppaa jopa 80% ennakkorahoituksella</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/slider/metsapalvelu_metsakarelia_jatkuvakasvatus-min.jpg"
          alt="Harvennushakkuu"
        />

        <Carousel.Caption>
          <h5>Puukauppaa ilman avohakkuutta</h5>
          <p>Toteutamme jatkuvan kasvatuksen kohteet avaimet käteen periaatteella</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/slider/puukauppa_metsapalvelu_tukki-min.jpg"
          alt="Metsäomaisuus"
        />

        <Carousel.Caption>
          <h5>Metsäomaisuuden arvon kasvattaja</h5>
          <p>
          Puukauppaa riippumattomasti, kilpailukykyisesti sekä ilman turhia välikäsiä
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/slider/koivikko_mets%C3%A4karelia_puukauppa_metsapalvelu-min.jpg"
          alt="metsänhoitopalvelut Joensuussa ilomantsissa ja Kiteellä"
        />

        <Carousel.Caption>
          <h5>Hoidamme metsäsi kuntoon</h5>
          <p>
          Metsänhoitotyöt helposti ja vaivattomasti. 
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
