import React from "react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

export default function Hakkuutyo () { return (
  <div>
   <h1 className="ekaotsikko_sivu" >Hakkuupalvelut ja -menetelmät</h1>
 
  <Container>
<Row className="float-right">
<Col>
  <Image className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/Ponsse_hakkuuty%C3%B6t.jpg" rounded fluid/>
</Col>
</Row>
</Container>
    
<p></p>
<div className="esittely_sivu">
<p>Operoimme Itä-Suomessa usean metsäkoneketjun voimin. Käytössämme on kokeneet kujettajat, jotka hoitavat vaikeatkin kohteeet laadukkaasti ja metsääsi kunnioittaen.</p>

<p>Toteutamme niin perinteisiä alaharvennuksia ja yhä kasvavissa määrin jatkuvan kasvatuksen menetelmän mukaisia käsittelytapoja, ja joita ovat.</p>
<p>
<li>pominta-/yläharvennus-</li>
<li>pienaukko-</li>
<li>siemenpuu- ja </li>
<li>suojuspuuhakkuut sekä </li>
<li>ylispuiden poisto </li>
</p>
<p>Hyödynnettävä hakkuumenetelmän valitaan metsätyypin, rakenteen, iän ja maaperän perusteella sekä tietysti metsänomistajan toivetta kunnioittaen.</p>
<p>Jatkuvassa kasvatuksessa ei tehdä suuria avohakkuita. Sillä metsää ei hakata täysin paljaaksi, vaan osa puustosta jätetään paikoilleen. Tällöin jäljelle jäävät puut saavat kasvutilaa. Tällöin tyypillisesti kuusikoissa käytetään poiminta- ja pienaukkohakkuita, männiköissä siemen- ja suojuspuuhakkuita ja ylispuuston kasvatusta. Hakkuita jatkuvassa kasvatuksessa tehdään keskimäärin 15–20 vuoden välein. Etuna menetelmässä on myös taimikon istutus- ja hoitokustannusten minimointi</p>
<p>Jatkuvan kasvatuksen hakkuumenetelmään siirtyminen edellyttää ammattitaito niin työn suunnittelussa kuin toteutuksessa. Esimerkiksi ensiharvennuskuviot, joilla pyritään siirtymään tulevaisuudessa jatkuvaan kasvatukseen edellyttää kokemusta ja arviointikykyä. Tällöin hakkukoneen kuljettajan tulee nähdä etukäteen, miltä metsä näyttää 15 vuoden kuluttua. Lisäksi menetelmään liittyy alaharvennuksesta poikkeavia toimintamalleja kuten esim. jokaisella harvennuskerralla ajetaan uudet ajourat ja jolloin vanhat urat saavat rauhassa taimettua.</p>
<p>Olipa mielessäsi mikä hakkuutapa tahansa ja haluat laadukkaan toteutuksen, niin älä epäröi olla yhteydessä metsäasiantuntijoihimme keskustellaksesi palveluistamme tarkemmin.</p>
<p>Antamalla suostumuksen Metsään.fi palvelusta voit antaa metsätilasi tiedot nähtäväksi asiantuntijallemme. Tämä nopeuttaa palvelua ja työn tekemistä. Suostumus annetaan jokaiselle metsätilalle ja toimijalle erikseen. Suostumuksen voi nopeiten antaa oheisesta linkistä:  <a className="vari" href="http://asiointi.metsaan.fi/etusivu/-/metsanomistaja-tunnistaudu-suomifi/tunnistaudu?suostumusToimijaId=58198&amp;backUrl=http%3A%2F%2Fmetsakarelia.fi">Siirry metsään.fi palveluun</a></p>

</div>
</div>
);

}