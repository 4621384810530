import React from "react";
import Etusivu from "./etusivu";
import Puukauppa from "./puukauppa_joensuu_kitee_ilomantsi_tohmajarvi";
import Metsurityot from "./metsanhoitopalvelu_joensuu_kitee_ilomantsi_tohmajarvi";
import Tietosuoja from "./tietosuoja";
import Hakkuutyo from "./harvennushakkuut_joensuu_ilomantsi_kitee_tohmajarvi";
import Navi from "./menu"
import Footer from "./footer"
import Tyota from "./tyotatarjolla"
import Asiakastarina from "./jatkuva_kasvatus_joensuu_ilomantsi_kitee_tohmajarvi"
import ScrollToTop from "./scrolltotop"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import "./styles.css";

function App() {
  return (
    <Router>
    <ScrollToTop/>
     <div>
        <Navi/>
                <Link to="/popup"> </Link>
      
      
      <Switch>

     <Route exact path="/">
      <Redirect to="/etusivu" />
      
      </Route>
      <Route path="/etusivu">
            <Etusivu />
          </Route>
          <Route path="/puukauppa_joensuu_kitee_ilomantsi_tohmajarvi">
            <Puukauppa />
          </Route>
          <Route path="/tietosuoja">
            {Tietosuoja}
          </Route>
          <Route path="/metsanhoitopalvelu_joensuu_kitee_ilomantsi_tohmajarvi">
            <Metsurityot />
          </Route>
          <Route path="/harvennushakkuut_joensuu_ilomantsi_kitee_tohmajarvi">
            <Hakkuutyo />
         </Route>
         <Route path="/tyotatarjolla">
            <Tyota/>
          </Route>
          <Route path="/jatkuva_kasvatus_joensuu_ilomantsi_kitee_tohmajarvi">
            <Asiakastarina/>
          </Route>
       
      </Switch>
     
       <Footer/>
       </div>
    </Router>
    
  );
}

export default App;
