import React from "react";
import Modal from "react-bootstrap/Modal";


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      
    >
      <Modal.Header className="reunat" closeButton>
        <Modal.Title  id="contained-modal-title-vcenter">
        metsään.fi suostumus
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
        <p>Puukauppaa tai metsänhoitotöitä suunniteltaessa voit antaa katseluluvan omiin metsätietoihisi metsään.fi palvelusta. Tämä nopeuttaa tarjousten  laatimista sekä fiksujen neuvojen antamista. Voit antaa suostumuksesi oheisen linkin kautta</p>
        
        <h3 ><a className="vari" href="http://asiointi.metsaan.fi/etusivu/-/metsanomistaja-tunnistaudu-suomifi/tunnistaudu?suostumusToimijaId=58198&amp;backUrl=http%3A%2F%2Fmetsakarelia.fi">Siirry metsään.fi palveluun</a></h3>
        
      </Modal.Body>
      <Modal.Footer className="reunat">
        
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
