import React from "react";
import Image from 'react-bootstrap/Image'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'



export default function Asiakastarina () { return (
  <div >
  <div><h1 className="ekaotsikko_sivu">TOINEN JATKUVAN KASVATUKSEN HAKKUUNI</h1></div>

 <div className="esittely_sivu">
    <p>
    Myytti 1: Jatkuvassa kasvatuksessa puun korjuu on olennaisesti kalliimpaa kuin tasaikäismetsätaloudessa
    </p>
    <p>
    Myytti 2: Jatkuvassa kasvatuksessa puusta saatava hinta on olennaisesti pienempi kuin tasaikäismetsätaloudessa
    </p>
    <p>
    Myytti 3: Suurten puiden hakkuu tuhoaa alikasvoksen, varsinkin pakkasella
    </p>
    <p>
    Toinen jatkuvan kasvatuksen leimikkoni hakattiin tammikuussa 2019. Maassa oli lunta, ja pakkasta oli joinakin päivinä 25 astetta. Palsta sijaitsee Joensuun Hammaslahdessa. Suurin osa leimikosta oli lehtomaisen kankaan kuusivaltaista metsää, mutta joukossa oli myös suota. Suometsiköissä oli kuusialikasvosta, ja mänty oli vallitsevan jakson pääpuulaji. Mäntyjen lisäksi vallitsevassa puujaksossa oli harvakseltaan järeitä kuusia.
    </p>
    <p >
    Hakkuun organisoi Metsä-Karelia Oy, joka osti puut minulta ja myi ne edelleen sahoille ja sellufirmoille. Hakkuun toteutti Vepsäläisen Metsäkoneyhtiö. Hakkuuohjeet olivat:
    </p>
    <ul>
    <li>Tee leveät ajourat ja havuta ne</li>
    <li>Poista järeät kuuset (0.8–1.3 m3) ja yläharvenna suokuvioiden mäntyjaksoa</li>
    <li>Yläharvenna pienempää tukkipuustoa</li>
    <li>Älä poista kuiturunkoja</li>
    <li>Jätä kaikki männyt ja koivut hakkaamatta kuusivaltaisilla kuvioilla</li>
    <li>Jäävän puuston ei tarvitse olla tasainen: jätä pienemmän puuston ryhmät hakkaamatta; suurten puiden ryhmät voit poistaa kokonaan</li>
    </ul>
    <p>
    Ohjeiden lisäksi tein pari pientä mallileimausta, joissa nauhoitin puut, jotka tulee poistaa. Toisella värillä nauhoitin puita, jotka tulisi jättää kasvamaan. Kiertelin metsikkökuvioita tunnin verran hakkuukoneen kuljettajan kanssa käyden läpi hakkuun periaatteita. Myöhemmät palstalla käynnit olivat hakkuujäljen ja komeiden tukkikasojen ihastelua. Tukkikasojen vieressä oli pieniä kuitupuunyssäköitä.
    </p>
    <p>
    Hakkuukertymä oli useimmilla kuvioilla reippaasti yli 100 m3/ha ja tukkiosuus oli suuri, varsinkin kivennäismaiden kuusivaltaisilla kuvioilla (kuvat 1–2). Vaikka tukin hinta olisi pienempi kuin avohakkuussa, myydyn kuutiometrin keskihinta voi silti olla yläharvennuksessa parempi. Alla oleva kuvitteellinen esimerkki havainnollistaa asiaa (hinnat €/m3):
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva1_Pukkala_Mets%C3%A4karelia-min.png" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Jos otetaan huomioon puun huono hinta ensiharvennuksessa ja varttuneen metsän alaharvennuksessa, käy helposti niin, että jatkuvassa kasvatuksessa myydyn puukuutiometrin hinta on keskimäärin parempi. Jotkut ajattelun sivuuttavat henkilöt tosin kiistävät tämän vetoamalla hintatilastoihin, joissa tukin hinta on kasvatushakkuussa jopa 10 €/m3 pienempi kuin päätehakkuussa. Hintatilastot kuitenkin heijastavat tilannetta tasaikäismetsätaloudessa. Tilastojen ongelma on, että myydyn puun järeys eri hakkuutavoissa ei käy niistä ilmi. Jatkuvassa kasvatuksessa poistettavat puut ovat huomattavasti järeämpiä kuin alaharvennuksessa, minkä vuoksi niistä maksetaan paremmin.
    </p>
    <p>
    Hakkuutapoja mietittäessä tukin hintaa olennaisempi asia on tukin osuus myydystä puusta. Tosin vieläkin olennaisempaa olisi pohtia, kuinka nopeasti ja kuinka paljon metsä antaa seuraavan kerran hakkuutuloa. Ei myöskään kannata unohtaa, että avohakkuuta seuraa joukko pakollisia kustannuksia. Maisema- ja luontoarvot ovat myös useimmille tärkeitä.
    </p>
    <p>
    Yläharvennuksessa päästään suurempaan tukkiosuuteen kuin avohakkuussa, koska pienimmät puut jätetään hakkaamatta. Tukin osuus riippuu myös siitä, missä määrin ostajan intresseissä on maksimoida tukkisaantoa. Tässä mielessä saha on varteenotettava ostajavaihtoehto, vaikka sen tarjoama hinta tukista olisi pienempi kuin kilpailijoilla.
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva2_Pukkala_metsapalvelu-min.jpg" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Kuva 1. Lehtomaisen kankaan kuusikko, jossa poistettujen puiden keskijäreys oli noin 0.9 m3. Hakkuukertymä oli runsaat 6000 €/ha. Pakkasen, lumen ja havutuksen ansiosta ajourapainaumia ei ollut, ja korjuuvauriot olivat muutenkin lähes olemattomat.
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva3_Pukkala_Metsapalvelu_puukauppa-min.jpg" rounded fluid/>
  </Col>
 </Row>
</Container>

    <p>
    Kuva 2. Suuren tukkiosuuden ansiosta myydyn puun keskihinta oli hyvä.
    </p>
    <p>
    Se, kuinka vahvasti tukin osuus riippuu esim. latvaläpimitasta ja tukkien tavoitellusta pituusjakaumasta, on kytköksissä tukkirunkojen järeyteen: mitä isompia hakattavat tukkirungot ovat, sitä vähemmän tukkiosuutta voi pienentää tukkien pituuksilla ja latvaläpimitoilla kikkailemalla. Jatkuvassa kasvatuksessa metsikön suurimmat puut kasvavat harvassa, minkä vuoksi niiden arvokasvu suhteessa puiden arvoon on parempi kuin samankokoisilla puilla tasaikäismetsässä. Jatkuvan kasvatuksen puu saavuttaa taloudellisen hakkuukypsyyden suuremmalla läpimitalla kuin tasaikäismetsässä.
    </p>
    <p>
    Jatkuvassa kasvatuksessa myyntipuut kannattaa siis kasvattaa isommiksi, mikä suurentaa tukkiosuutta sekä suoraan että epäsuorasti vähentämällä kikkailun vaikutusta. Isojen puiden kasvattelu tietenkin hidastaa pienemmän puuston kasvua. Puun laadun kannalta tämä on pelkästään hyvä asia, koska puusta tulee ohutlustoista. Taloudellisessa mielessä pienten puiden hidas kasvu ei ole ongelma, koska pienissä puissa on kiinni vähän pääomaa. Hitaasta kasvusta huolimatta tuotantoon sidottu pääoma tuottaa pienissä puissa riittävästi.
    </p>
    <p>
    Hakattavien tukkien pituuksien, latvaläpimittojen ja muiden laatuvaatimusten vaikutus tukkisaantoon ja hakkuutuloon on asia, jota myyjän on hankala hahmottaa. Tästä hankaluudesta pääsisi eroon sillä, että kilpailijat tarjoaisivat leimikosta yhden ainoan kuutiometrin hinnan. Silloin myyjälle olisi samantekevää, kuinka ostaja jakaa hakatut kuutiometrit eri puutavaralajeiksi.
    </p>
    <p>
    Yläharvennuksessa puuta kertyy joutuisasti, koska hakataan järeitä puita ja kaikki pienet puut jätetään hakkaamatta (kuva 3). Varsinkin hakkuukoneen tuntituotos riippuu voimakkaasti hakattujen runkojen koosta. Hakkuutapa on rungon kokoon verrattuna vähäpätöinen tekijä (kuva 4).
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva4_pukkala_puukauppa_metsapalvelu-min.jpg" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Kuva 3. Järeistä puista kertyy nopeasti kuutiometrejä.
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva5_pukkala_puukauppa_metsapalvelu-min.png" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Kuva 4. Yhden kuutiometrin hakkuuseen kuluvan ajan riippuvuus rungon järeydestä.
    </p>
    <p>
    Ajokoneen tuottavuus riippuu lähikuljetusmatkan ja maaston lisäksi siitä, kuinka paljon metsässä on poisajettavaa puuta yhtä ajourametriä kohti. Tässä suhteessa avohakkuu peittoaa yläharvennuksen. Poistettavien runkojen koon vaikutus hakkuun kokonaiskustannukseen (hakkuu + lähikuljetus) on kuitenkin edelleen selvä (kuva 5). Kuvasta 5 käy ilmi tunnettu tosiasia, että tasaikäismetsän ensiharvennus on kallista, varttuneen metsän alaharvennus selvästi halvempaa, ja avohakkuu vieläkin halvempaa. Jatkuvan kasvatuksen yläharvennus on varttuneen metsän alaharvennuksen ja avohakkuun välissä.
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva6_pukkala_metsapalvelu_puukauppa.gif" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Kuva 5. Korjuukustannuksen (hakkuu + lähikuljetus) riippuvuus rungon keskikoosta eri hakkuutavoissa, kun kertymät ovat eri hakkuutavoille tyypillisiä. Kustannukset on laskettu Rummukaisen ym. (1995) ajanmenekkimalleilla ja nykyisillä tuntitaksoilla. Ajanmenekkimallit ottavat huomioon hakkuutavan (avohakkuulle ja harvennukselle on omat mallit).
    </p>
    <p>
    Yläharvennuksessa puuta kertyy joutuisasti, koska hakataan järeitä puita ja kaikki pienet puut jätetään hakkaamatta (kuva 3). Varsinkin hakkuukoneen tuntituotos riippuu voimakkaasti hakattujen runkojen koosta. Hakkuutapa on rungon kokoon verrattuna vähäpätöinen tekijä (kuva 4).
    </p>
    <Container>
    <Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva7_puukauppa_jatkuvakasvatus_metsapalvelu-min.png" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Kuva 6. Korjuukustannusten suuruusluokka eri hakkuutavoissa. Punainen pylväs on kertymällä painotettu keskiarvo tasaikäismetsätalouden eri hakkuiden korjuukustannuksesta.
    </p>
    <Container>
<Row className="center-left">
  <Col>
    <Image Row className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/pukkala_puukauppa_metsapalvelu/kuva8_puukauppa_pukkala_jatkuvakasvatus-min.jpg" rounded fluid/>
  </Col>
 </Row>
</Container>
    <p>
    Kuva 7. Kuusialikasvos säilyi riittävän tiheänä, vaikka metsiköstä poistettiin runsaasti järeitä mäntyjä ja kuusia.
    </p>
    <p>
    Olen osoittanut, että myytit 1 ja 2 tosiaankin ovat myyttejä, ainakin tässä leimikossa. Entäpä sitten kuusialikasvoksen tuhoutuminen, kun sen päältä poistetaan runsaasti järeää puustoa talvipakkasella? Kuva 7 osoittaa, että näin ei käynyt, kiitos taitavan konemiehen, vaikka pakkasta oli enemmän kuin jääpallossa katsojia.
    </p>
    <p>
    Timo Pukkala
   </p>
  
</div>
  </div>
);
}



