import { Link } from "react-router-dom";
import React from "react";
import { Envelope, Telephone, GeoAlt} from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
function Footer() {
  return (

<Container className="footer" fluid>
  <Row>
    <Col ><p > Metsä-Karelia Oy</p> 
    <p ><Link className="vari"  to="/tietosuoja">
           <i> Tietosuojaseloste </i>
             </Link> © 2020 Metsä-Karelia Oy</p>
             </Col>
    <Col>  <p><Envelope color="#81d742" size={20} /> seppo@metsakarelia.fi
</p>
<p>
<Telephone color="#81d742" size={20}/> +358 400 271 926
</p>
<p>
<GeoAlt color="#81d742" size={20}/> Ylätie 47, 82900 Ilomantsi 

</p>
</Col>
  </Row>
         
</Container>
  );
}
 export default Footer;