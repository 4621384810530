import React from "react";

const Puukauppa = (
  <img
    className="responsive"
    src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/puukauppa_suomi-min.jpg"
    alt="Puukauppa"
    style={{ verticalAlign: "middle" }}
  />
);

const Ilmanavohakkuuta = (
  <img
    className="responsive"
    src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/ajokonetoissa_ilomantsi-min.jpg"
    alt="Jatkuva kasvatus"
    style={{ verticalAlign: "middle" }}
  />
);
const Metsuripalvelut = (
  <img
    className="responsive"
    src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/metsapalvelut_metsanhoito-min.jpg"
    alt="Metsuripalvelu"
    style={{ verticalAlign: "middle" }}
  />
);

export default Puukauppa;
export { Ilmanavohakkuuta };
export { Metsuripalvelut };
