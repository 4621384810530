
const Tietosuoja=
<div className="esittely_sivu">
<p>1. REKISTERINPITÄJÄ</p>

<p>Metsä-Karelia Oy, </p>
<p>Ylätie 47</p>
<p>82900 Ilomantsi</p>
<p>0400271926</p>
<p>2.YHTEYSHENKILÖ</p>
<p>Seppo Penttinen</p>
<p>sposti:asiakaspalvelu@metsakarelia.fi</p>
<p>p.0400271926</p>
<p>3.REKISTERIN NIMI</p>
<p>Metsä-Karelia Oy:n markkinointirekisteri</p>
<p>4.HENKILÖTIETOJEN KÄSITTELYN TARKOITUS</p>
<p>Rekisteriin tallennettuja tietoja käytetään markkinointiin, asiakassuhteiden hoitamiseen sekä asiakastiedon seurantaan ja analysointiin, joiden tavoitteena on viestinnän kohdentaminen, yleiset myynti- ja markkinointitarkoitukset sekä muut verkkopalveluihin liittyvät tarkoitukset.</p>
<p>5.REKISTERIN TIETOSISÄLTÖ</p>
<p>Rekisteriin tallennettavia tietoja ovat: henkilön nimi, yhteystiedot (puhelinnumero, sähköpostiosoite, osoite), Y-tunnus/HETU, metsätila- ja puustotiedot, verkkosivuston kävijä ja käyttötiedot (käytämme evästeitä), tunnukset/profiilit sosiaalisen median palveluissa, tiedot tilatuista palveluista ja niiden muutoksista, laskutustiedot, muut asiakassuhteeseen ja tilattuihin palveluihin liittyvät tiedot.</p>
<p>6.SÄÄNNÖNMUKAISET TIETOLÄHTEET</p>
<p>Rekisteriin tallennettavat tiedot saadaan mm. metsakarelia.fi sivuston kautta automaattisesti evästeiden avulla (hyödyntäen GoogleAnalyticsia) sekä verkkolomakkeella kerätyistä tiedoista, metsään.fi palvelusta käyttäjän antamalla valtuutuksella, metsätietolain 9 § perusteella, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan.</p>
<p>7.EVÄSTEIDEN KÄYTTÖ(cookies)</p>
<p>Käytämme sivuillamme ns. cookie-toimintoa eli evästeitä. Eväste on pieni, käyttäjän tietokoneelle lähetettävä ja siellä säilytettävä tekstitiedosto, joka mahdollistaa internet-sivujen ylläpitäjän tunnistamaan usein sivuilla vierailevat kävijät, helpottamaan kävijöiden kirjautumista sivuille sekä mahdollistamaan yhdistelmätiedon laatimisen kävijöistä. Tämän palautteen avulla pystymme jatkuvasti parantamaan sivujemme sisältöä. Evästeet eivät vahingoita käyttäjien tietokoneita tai tiedostoja. Käytämme niitä siten, että voimme tarjota asiakkaillemme kunkin yksilöityjen tarpeiden mukaisia tietoja ja palveluita.</p>
<p>Mikäli sivuillamme vieraileva käyttäjä ei halua meidän saavan edellä mainittuja tietoja evästeiden avulla, useimmat selainohjelmat mahdollistavat eväste-toiminnon poiskytkemisen. Tämä tapahtuu yleensä selaimen asetuksien kautta.</p>
<p>On kuitenkin hyvä ottaa huomioon se, että evästeet voivat olla tarpeellisia joidenkin ylläpitämiemme sivujen ja tarjoamiemme palveluiden asianmukaiselle toimimiselle.</p>
<p>8.AUTOMAATTINEN PÄÄTÖKSENTEKO</p>
<p>Automatisoituja yksittäispäätöksiä (EU:n tietosuoja-asetuksen artikla 22) ei tehdä.</p>
<p>9.TIETOJEN SÄÄNNÖNMUKAISET LUOVUTUKSET</p>
<p>Rekisterinpitäjä ei luovuta rekisterin tietoja ulkopuolisille, paitsi Suomen viranomaistoimien niin edellyttäessä.</p>
<p>10.REKISTERIN SUOJAUKSEN PERIAATTEET</p>
<p>Sähköiset tiedot on suojattu käyttäjätunnuksin ja salasanoin. Tietojen käyttöoikeus on vain niillä rekisterinpitäjän palveluksessa olevilla henkilöillä, jotka tarvitsevat tietoja tehtävissään.</p>
<p>11.TARKASTUOIKEUS JA OIKEUS VAATIA TIEDON KORJAAMISTA</p>
<p>Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle.</p>
<p>12.MUUT HENKILÖTIETOJEN KÄSITTELYYN LIITTYVÄT OIKEUDET</p>
<p>Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle.</p>
</div>


export default Tietosuoja;