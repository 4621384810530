import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {Accordion} from "@material-ui/core";
import {AccordionSummary} from "@material-ui/core";
import {AccordionDetails} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .012)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(AccordionDetails);

export default function Vinkit() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="vinkit">
      <ExpansionPanel
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>
            Miksi metsätilallani kannattaisi siirtyä jatkuvaan kasvatukseen?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          Jatkuvan kasvatuksen etuna on metsänhoidon kustannuksien alentuminen sekä puunmyyntitulojen nouseminen. Tällöin hakkuussa poistetaan metsän järeimpiä puita, ja joista saa myös parhaan hinnan.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>
            Teettekö muunlaisia puukauppoja ja hakkuita kuin jatkuvan
            kasvatuksen mukaisia?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Kyllä. Valtaosa puukaupoista ja hakkuista toteutetaan alaharvennuksina. Jatkuvan kasvatuksen hakkuut ovat kuitenkin yleistymässä kovaa vauhtia.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography>
            Onko ennakkorahoitusta saatavissa puukauppaa tehtäessä?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Kyllä on, jopa 80% puukaupasta voidaan maksaa ennakkorahoituksena.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography>Millä alueilla yrityksenne toimii?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Toimimme Joensuun, Ilomantsin, Tohmajärven ja Kiteen aluilla.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography>
            Kuka hakkuutyön tulee toteuttamaan ja onko mahdollista vierailla
            referenssikohteiilla?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Oman konekaluston ja kuskien lisäksi teemme yhteistyötä muutamien erinomaista työjälkeä tekevien koneyrittäjien kanssa. Kumppaneitamme ovat mm: Metsäkoneyhtiö Vepsäläinen Tohmajärven ja Kiteen alueilla sekä. Metsämyller Joensuun alueella. Olet tervetullut tutustumaan referenssikohteisiimme.     </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6d-content"
          id="panel6d-header"
        >
          <Typography>
            Miksi kannattaisi valita Metsä-Karelia Oy puukauppoja ja hakkuita
            suunniteltaessa?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Saat puustasi hyvän hinnan, koska olemme riippumaton toimija ja välitämme puita useille eri toimijoille. Tavoitteenamme on aina erinomainen asiakaskokemus ja työjälki - ja joista olemme saaneet kehuja useasti. 
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
