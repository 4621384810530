import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Itä-suomalaisen metsätilan omistaja?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
        <h4>
          Ostamme puuta aktiivisesti Ilomantsin, Joensuun, Tohmajärven ja Kiteen alueilla. Soita numeroon p. 0400 271926 tai jätä yhteystietosi verkkolomakkeella. Teemme sinulle hyvän kauppatarjouksen.
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Popup() {
  const [modalShow, setModalShow] = React.useState(false);
  setTimeout(() => setModalShow(true), 10000000);
  return (
    <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    />
  );
}

export default Popup;
