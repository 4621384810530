import React from "react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

export default function Puukauppa () { return (
    <div>
     <h1 className="ekaotsikko_sivu" >Puukauppaa Joensuun, Ilomantsin, Kiteen ja Tohmajärven alueilla</h1>
   
    <Container>
<Row className="float-right">
  <Col>
    <Image className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/tukkirekka.jpg" rounded fluid/>
  </Col>
 </Row>
</Container>
      
<p></p>
<div className="esittely_sivu">
<p >Ostamme aktiivisesti puuta pystykaupalla Ilomantsin, Tohmajärven, Joensuun ja Kiteen alueilta. Olemme erikoistuneet harvennusleimikoihin sekä jatkuvaan kasvatukseen. Avohakkuita emme juurikaan tee. Maksamme metsänomistajalle kilpailukykyisen kantohinnan ja jopa 80% ennakkorahoituksen.</p>

<p>Puukauppoihin liittyen kuulemme usein metsänomistajien kysyvän, mitkä tekijät vaikuttavat leimikosta saatavaan hintaan. Kokemuksemme mukaan näitä ovat esim:</p>
<p>
<li>puiden määrä, järeys ja laatu</li>
<li>leimikon korjuukelpoisuus – kelirikon aikaan ja kesällä korjattavissa olevat metsäalueet käyvät paremmin kaupaksi</li>
<li>hakkuualojen ennakkoraivauksen tarve – metsäkoneen tehokas ja laadukas toiminta edellyttää toisinaan ennakkoraivausta.</li>
<li>hakkuutapa – tehdäänkö alaharvennus vaiko jatkuvan kasvatuksen mukainen poimintahakkuu</li>
</p>
<p>Hoidamme korjuun laadukkaasti ja nopeasti. Harvennuskohteissa käytämme kevyttä kalustoa. Toiminnallamme työllistämme useita moto- ja kuljetusyrittäjiä Itä-Suomessa. Toimimme yhteistyössä alan johtavien metsäteollisuusyritysten kanssa.</p>
<p>Ota yhteyttä puunostajaamme sopiaksesi ilmaisen arviointikäynnin.</p>
<p>Antamalla suostumuksen Metsään.fi palvelusta voit antaa metsätilasi tiedot nähtäväksi puukauppiaallemme. Tämä nopeuttaa puukauppaa ja tarjouksen tekemistä. Suostumus annetaan jokaiselle metsätilalle ja toimijalle erikseen.  Suostumuksen voi nopeiten antaa oheisesta linkistä: <a className="vari" href="http://asiointi.metsaan.fi/etusivu/-/metsanomistaja-tunnistaudu-suomifi/tunnistaudu?suostumusToimijaId=58198&amp;backUrl=http%3A%2F%2Fmetsakarelia.fi">Siirry metsään.fi palveluun</a></p></div>
</div>
);

}