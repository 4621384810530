import React from "react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

export default function Metsurityot () { return (
  <div>
   <h1 className="ekaotsikko_sivu" >Metsänhoitopalvelut</h1>
 
  <Container>
<Row className="float-right">
<Col>
  <Image className="d-inline-block" src="https://s3.eu-north-1.amazonaws.com/metsakarelia.fi/metsanhoitopalvelu.jpg" rounded fluid/>
</Col>
</Row>
</Container>
    
<p></p>
<div className="esittely_sivu">
<p>Jätä metsänhoito ja metsurityöt ammattilaiselle – meillä on siitä pitkä kokemus. Mikäli tarvitset metsän- tai maisemanhoitoon liittyviä metsäpalveluita olemme käytössäsi.  Hoidamme metsurityöt kaikissa kohteissa luotettavasti ja turvallisesti.</p>

<p>Metsurimme palvelee sinua seuraavilla alueilla:</p>

<ul>
<li>Taimikon istutus</li>
<li>Nuoren metsän varhais ja taimikon perkaus</li>
<li>Nuoren metsän kunnostusraivaus</li>
<li>Rajojen aukaisu</li>
<li>Tonttipuuhakkuut</li>
<li>Sähkölinjojen raivaus</li>
<li>Pihapuun kaadot myös kiipeämällä</li>
<li>Ennakkoraivaukset</li>
<li>Uudistusalan raivaus</li>
<li>Maisemahakkuut ja –metsänhoito</li>
<li>Luonnonhoitokohteet</li>
<li>Lannoitteen levitys</li>
<li>Hirvituhojen ennakkoehkäisy</li>
<li>Yksityistiet</li>
</ul>


</div>
</div>
);

}